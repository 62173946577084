<template>
  <main class="mainPage">
    <div class="container">
      <div class="mainPage__mainSection" @touchstart="setTouchStart($event)"
           @touchend="setTouchEnd($event)">
        <div class="mainPage__mainSection-backgrounds">
          <div v-if="isEnglish">
            <div
              class="background-main"
              :style="{
            left: currentBackgroundIndex === 3 ? '-100%' : currentBackgroundIndex === 2 ? '100%' : 0,
            opacity: currentBackgroundIndex !== 1 ? 0 : 1,
          }"></div>
            <div
              class="background-kvota-eng"
              :style="{
            left: currentBackgroundIndex === 3 ? '-100%' : currentBackgroundIndex === 1 ? '100%' : 0,
            opacity: currentBackgroundIndex !== 2 ? 0 : 1,
          }"></div>

          </div>

          <div v-else>
            <div
              class="background-main"
              :style="{
            left: currentBackgroundIndex === 4 ? '-100%' : currentBackgroundIndex === 3 ? '100%' : 0,
            opacity: currentBackgroundIndex !== 1 ? 0 : 1,
          }"></div>
            <div
              class="background-kvota"
              :style="{
            left: currentBackgroundIndex === 4 ? '-100%' : currentBackgroundIndex === 1 ? '100%' : 0,
            opacity: currentBackgroundIndex !== 2 ? 0 : 1,
          }"></div>
            <div
              class="background-credit"
              :style="{
            left: currentBackgroundIndex === 4 ? '-100%' : currentBackgroundIndex === 2 ? '100%' : 0,
            opacity: currentBackgroundIndex !== 3 ? 0 : 1,
          }"></div>
          </div>
        </div>


        <h1 v-if="currentBackgroundIndex === 1" class="mainPage__mainSection-title">
          <span v-if="isEnglish">
            <span v-if="isMobile">
              Welcome to the Official Website
            </span>
            <span v-else>Welcome to the Official Website</span>
          </span>
          <span v-else>
            <span v-if="isMobile">
              Добро пожаловать на официальный сайт
            </span>
            <span v-else>Добро пожаловать на официальный сайт</span>
          </span>
        </h1>

        <h1 v-else-if="currentBackgroundIndex === 3" class="mainPage__mainSection-title">
          <span v-if="isMobile">
            Узнай о возможности получения <br/>
            образовательного кредита <br/>
            с государственной поддержкой
          </span>
          <span v-else style="font-size: 24px"> Узнай о возможности получения образовательного кредита<br/>
          с государственной поддержкой
          </span>
        </h1>

        <a v-else-if="currentBackgroundIndex === 2" href="/kvota" class="mainPage__mainSection-title link ">
            <span v-if="isEnglish">
            <span v-if="isMobile">
             Get an education according to the quota of the<br/>
              Government of the Russian Federation <br/>
            </span>
            <span v-else style="font-size: 24px">
               Get an education according to the quota of the<br/> on Admission to Russian Universities
            </span>
            <br v-if="!isMobile"/>
              <span v-if="isMobile" style="font-size: 12px; font-weight: 500">Since 2023 the quota for the education of foreign students will be 30 thousand places</span>
          <span v-else style="font-size: 18px; font-weight: 500">Since 2023 the quota for the education of foreign students will be 30 thousand places</span>

          </span>

          <span v-if="!isEnglish">
          <span v-if="isMobile">
            Получи высшее образование в России <br/>
            по квоте Правительства Российской Федерации! <br/>
          </span>

          <span v-else style="font-size: 24px">  Получи высшее образование в России<br/>
          по квоте Правительства Российской Федерации!

          </span> <br v-if="!isMobile"/>
          <span v-if="isMobile" style="font-size: 12px; font-weight: 500">С 2023 года квота на образование иностранных граждан составит 30 тысяч мест</span>
          <span v-else style="font-size: 18px; font-weight: 500">С 2023 года квота на образование иностранных граждан составит 30 тысяч мест</span>

          </span>
        </a>

        <div class="mainPage__mainSection-flex">
          <div :style="{visibility: currentBackgroundIndex !== 1 ? 'visible' : 'hidden'}"
               class="mainPage__mainSection-link">
            <router-link class="link"
                         to="/sber_credit"
                         v-if="currentBackgroundIndex === 3">
              <span v-if="isMobile" style="font-size: 12px">
              Подробнее
              </span>
              <span v-else style="font-size: 24px" class="link">
              Подробнее
              </span>
            </router-link>

            <router-link class="link" to="/zakony/aa4c310a-56c6-427b-9e1e-63ab00958726"
                         v-if="currentBackgroundIndex === 2 ">
                              <span v-if="isMobile && !isEnglish" style="font-size: 12px">
                              Ознакомиться<br/>
                              с Постановлением Правительства РФ
                            </span>
              <span
                v-else-if="!isMobile && !isEnglish"
                style="font-size: 24px" class="link">
                              Ознакомиться с Постановлением Правительства РФ
                            </span>
              <span v-if="isMobile && isEnglish" style="font-size: 12px">
                              See the full text of the Decree
                            </span>
              <span
                v-else-if="!isMobile && isEnglish"
                style="font-size: 24px" class="link">
                              See the full text of the Decree
                            </span>

            </router-link>

          </div>

          <div class="mainPage__mainSection-widget-background">
            <div :style="{width: changeBackgroundProgress + '%'}"
                 class="widget-background__progress-bar"></div>
            <img class="widget-background__btn-left" @click="prevBackgroundImage"
                 src="../../assets/images/icon-arrow-background-btn-left.svg"/>
            <div class="widget-background__counter">
              <span v-if="isEnglish">
                 {{ englishCounter }}/{{ numberOfImages }}
              </span>
              <span v-else>
                 {{ currentBackgroundIndex }}/{{ numberOfImages }}
              </span>
            </div>
            <img class="widget-background__btn-right" @click="nextBackgroundImage"
                 src="../../assets/images/icon-arrow-background-btn-right.svg"/>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="mainPage__application-block" v-if="!isEnglish">-->
<!--      <div class="container">-->
<!--        <h2 class="mainPage__subtitle application__title">-->
<!--          Государственные услуги-->
<!--        </h2>-->
<!--        <a class="application__flex"-->
<!--           href="https://www.gosuslugi.ru/10077/1"-->
<!--           rel="noopener noreferrer nofollow"-->
<!--           target="_blank"-->
<!--        >-->
<!--          <img-->
<!--            alt="Логотип Госуслуг"-->
<!--            class="application__image"-->
<!--            src="@/assets/images/logo-podacha-documentov.png"-->
<!--          />-->
<!--          <div class="application__text">-->
<!--            <span v-if="isMobile">-->
<!--              Подача заявления <br/> на поступление в вуз-->
<!--            </span>-->
<!--            <span v-else>-->
<!--              Подача заявления на поступление в вуз-->
<!--            </span>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
    <!--        <MainReviewsContainer :style="{-->
    <!--            background: isEnglish ? '#e3eaf1' : '#FAFCFF', height: isEnglish ? '455px' : '445px'}"/>-->
    <div class="mainPage__resources-block" :style="{
      background: isEnglish ? '#FAFCFF' : '#e3eaf1'}">
      <div class="container">
        <h2 class="mainPage__subtitle resources__title" v-if="!isEnglish">Полезные ресурсы</h2>
        <h2 class="mainPage__subtitle resources__title-eng" :style="{ padding: isMobile ? '0' : ''}" v-else>useful
          sources</h2>
        <div class="resources__list">
          <main-page-resource-item
            v-for="(item, index) in resourceList"
            :key="index"
            :card-info="item"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MainPageResourceItem from "@/components/mainPage/MainPageResourceItem";
// import MainReviewsContainer from "@/components/mainPage/MainReviewsContainer";

export default {
  name: "MainPage",
  components: {
    MainPageResourceItem,
    // MainReviewsContainer,
  },
  data() {
    return {
      currentBackgroundIndex: 1,
      timer: 0,
      firstDelay: true,
      mobileWidth: null,
      touchStartCoordX: 0,
      touchEndCoordX: 0,
      resourceList: [
        {
          imageSrc: "logo-gosuslugi",
          title: "Портал государственных и  муниципальных услуг",
          titleEng: "Public Services Portal of the Russian Federation (Gosuslugi)",
          linkText: "gosuslugi.ru",
          link: "https://www.gosuslugi.ru/",
          tag: "#поступающим",
          tagEng: "#admission"
        },
        {
          imageSrc: "logo-postupai-pravilno",
          title: "Портал «Поступай правильно»",
          titleEng: "Postupai Pravilno – Information Portal on Admission to Russian Universities",
          linkText: "поступай-правильно.рф",
          link: "https://поступай-правильно.рф",
          tag: "#поступающим",
          tagEng: "#admission"
        },
        {
          imageSrc: "logo-education-in-russia",
          title: "Портал для иностранных граждан об обучении в России",
          titleEng: "Information Portal for Foreign Nationals on Education in Russia",
          linkText: "education-in-russia.com",
          link: "https://education-in-russia.com/",
          tag: "#поступающим по квоте Правительства РФ",
          tagEng: "#admissionwithinthequotaoftheGovernment"
        },
        {
          imageSrc: "logo-recognition",
          title: "ФГБУ «Главэкспертцентр»",
          titleEng: "FSBI «Glavekspertcentre»",
          linkText: "nic.gov.ru",
          link: "https://nic.gov.ru/",
          tag: "#поступающим с зарубежным образованием",
          tagEng: "#admissionbasedonforeigneducation"
        },
        {
          imageSrc: "logo-minobr",
          title: "Министерство науки и высшего образования РФ",
          titleEng: "Ministry of Science and Higher Education of the Russian Federation",
          linkText: "minobrnauki.gov.ru",
          link: "https://minobrnauki.gov.ru/",
          tag: "#поступающим",
          tagEng: "#admission"
        },
        {
          imageSrc: "logo-portal-ege",
          title: "Официальный информационный портал ЕГЭ",
          titleEng: "Information Portal of the Unified State Exam (USE)",
          linkText: "checkege.rustest.ru",
          link: "https://checkege.rustest.ru/",
          tag: "#поступающим на общих основаниях",
          tagEng: "#generaladmission"
        },
        {
          imageSrc: "logo-minobr",
          title: "Образование в России для иностранных граждан",
          titleEng: "Education in the Russian Federation for Foreign Nationals / Russia-edu",
          linkText: "russia-edu.minobrnauki.gov.ru",
          link: "https://russia-edu.minobrnauki.gov.ru/",
          tag: "#поступающим по квоте Правительства РФ",
          tagEng: "#admissionwithinthequotaoftheGovernment\n"
        },
        {
          imageSrc: "logo-rosobrnadzor",
          title: "Рособрнадзор",
          titleEng: "Federal Service for the Supervision of Education and Science (Rosobrnadzor)",
          linkText: "obrnadzor.gov.ru",
          link: "https://obrnadzor.gov.ru/",
          tag: "#поступающим",
          tagEng: "#admission"
        }
      ]
    };
  },
  mounted() {
    window.onresize = () => {
      this.mobileWidth = document.documentElement.clientWidth <= 768;
    };
    this.startTimer();
  },
  watch: {
    touchEndCoordX() {
      // свайп в мобильной версии
      const touchCoordsGap = this.touchStartCoordX - this.touchEndCoordX;
      if (touchCoordsGap > 50) {
        this.nextBackgroundImage();
      } else if (touchCoordsGap < -50) {
        this.prevBackgroundImage();
      }
    }
  },
  computed: {
    changeBackgroundProgress() {
      // регулирует заполнение прогресс-бара по таймеру
      // return this.timer / 70;
      return 0;
    },
    numberOfImages() {
      if (this.isEnglish) {
        return 1;
      } else {
        return 1;
      }
    },
    isEnglish() {
      this.restartTimer();
      return this.$store.getters.checkEnglishVersion;
    },
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
    englishCounter() {
      return this.currentBackgroundIndex > 3 ? this.currentBackgroundIndex - 3 : this.currentBackgroundIndex
    }
  },
  methods: {
    startTimer() {
      // Включает таймер для перелистывания изображений
      setInterval(() => {
        if (this.timer < 7000) {
          this.timer = this.timer + 10;
        } else {
          this.timer = 0;
          this.nextBackgroundImage();
        }
      }, 10);
    },
    restartTimer() {
      // перезапускает таймер при переключении языка
      this.timer = 0;
      this.currentBackgroundIndex = 1;
    },
    prevBackgroundImage() {
      if (this.currentBackgroundIndex === 1) {
        this.currentBackgroundIndex = this.numberOfImages;
      } else {
        this.currentBackgroundIndex--;
      }
      this.timer = 0;
    },
    nextBackgroundImage() {
      if (this.currentBackgroundIndex === this.numberOfImages) {
        this.currentBackgroundIndex = 1;
      } else {
        this.currentBackgroundIndex++;
      }
      this.timer = 0;
    },
    setTouchStart(event) {
      this.touchStartCoordX = event.changedTouches[0].clientX;
    },
    setTouchEnd(event) {
      this.touchEndCoordX = event.changedTouches[0].clientX;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.mainPage {
  min-width: $containerWidth;
  width: 100%;
  padding: 0;
  background-color: $backgroundColor;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
    //padding: 0 0 73px;
  }

  .container {
    padding: 0 10px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      max-width: $smPhoneWidth;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__mainSection {
    height: 580px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0 26px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 425px;
      padding: 32px 0 35px;
    }

    &-backgrounds {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      overflow-x: hidden;

      .background-main,
      .background-credit,
      .background-kvota,
        //.background-act,
        //.background-decree,
      .background-kvota-eng {
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        z-index: 0;
      }

      .background-main,
      .background-credit,
      .background-kvota,
        //.background-act,
        //.background-decree,
      .background-kvota-eng {
        transition: left 0.7s, opacity 0.4s;
      }

      .background-main {
        //background-image: url(../../assets/images/background-main.svg);
        background-image: url(../../assets/images/background-main-new.svg);
        background-size: 930px;
        background-position: center 5px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          background-image: url(../../assets/images/background-main-mobile.svg);
          background-size: 320px;
          background-position: center 105px;
        }
      }

      .background-credit {
        background-image: url(../../assets/images/background-main-credit.svg);
        background-size: 1100px;
        background-position: center 105px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          background-image: url(../../assets/images/background-main-mobile-credit.svg);
          background-size: 340px;
          background-position: center 95px;
        }
      }

      .background-kvota {
        background-image: url(../../assets/images/background-main-kvota.svg);
        background-size: 1100px;
        background-position: center 25px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          background-image: url(../../assets/images/background-main-mobile-kvota.svg);
          background-size: 335px;
          background-position: center;
        }
      }

      .background-kvota-eng {
        background-image: url(../../assets/images/background-main-kvota-eng.svg);
        background-size: 1100px;
        background-position: center 25px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          background-image: url(../../assets/images/background-main-mobile-kvota-eng.svg);
          background-size: 335px;
          background-position: center;
        }
      }
    }

    .link:hover {
      color: $color-blue;
    }

    &-title {
      color: $primary-color;
      font-size: $fontSizeXL;
      font-weight: 800;
      line-height: 33px;
      z-index: 1;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        padding-top: 0;
        font-size: $fontSizeMobileL;
        line-height: 16px;
      }
    }

    &-flex {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }

    &-link {
      margin-bottom: 10px;
      color: $primary-color;
      font-size: $fontSizeXL;
      font-weight: 800;
      text-decoration: underline;
      cursor: pointer;
      z-index: 5;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: $fontSizeMobileL;
        line-height: 16px;
      }
    }

    &-widget-background {
      min-width: 112px;
      align-self: center;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      color: $white-color;
      background-color: rgba($greyColorLight, $alpha: 0.6);
      border-radius: 40px;
      overflow: hidden;
      z-index: 1;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        min-width: 68px;
      }

      @keyframes fill-counter {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }

      .widget-background {

        &__progress-bar {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          background-color: $primary-color;
          z-index: -1;
        }

        &__btn-left, &__btn-right {
          padding: 10px;
          cursor: pointer;
          z-index: 5;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            transform: scale(0.5);
            margin: -35px 0;
            padding: 0 6px 0 3px;
          }
        }

        &__counter {
          width: 45px;
          padding: 8px 5px 7px;
          font-size: 18px;
          text-align: center;
          z-index: 5;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            width: 25px;
            padding: 3px 0;
            font-size: 11px;
          }
        }
      }
    }
  }

  &__subtitle {
    color: $primary-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
    text-transform: uppercase;
    display: inline-grid;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: $fontSizeMobileM
    }
  }

  &__application-block {
    width: 100%;
    margin-bottom: 20px;
    padding: 44px 0 57px;
    background-color: #e3eaf1;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 14px;
      padding: 14px 0 20px;
    }

    .application {
      &__flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $black-color;
        font-size: $fontSizeXL;
        line-height: 33px;
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }

        &:active {
          color: $primary-color;
        }

        @media (max-width: $mobile-version) and (orientation: portrait) {
          font-size: $fontSizeMobileXL;
          line-height: 16px;
        }
      }

      &__title {
        margin-bottom: 30px;
        line-height: 23px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          margin-bottom: 16px;
          line-height: 13px;
        }
      }

      &__image {
        width: 60px;
        margin-right: 20px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          width: 35px;
          margin-right: 9px;
        }
      }

      &__text {
        font-size: $fontSizeXL;
        line-height: 33px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          font-size: $fontSizeMobileXL;
          padding-right: 115px;
          line-height: 16px;
        }
      }
    }
  }


  &__resources-block {
    background-color: #E3EAF1;
    padding-bottom: 60px;

    .resources {
      &__title {
        padding-top: 44px;
        margin-bottom: 43px;
        color: $primary-color;
        font-size: 18px;
        font-weight: 800;
        line-height: 23px;
        text-transform: uppercase;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          padding-top: 14px;
          margin-bottom: 10px;
          font-size: $fontSizeMobileM;
        }
      }

      &__title-eng {
        padding-top: 44px;
        margin-bottom: 43px;
        color: $primary-color;
        font-size: 18px;
        font-weight: 800;
        line-height: 23px;
        text-transform: uppercase;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          padding-top: 14px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: $fontSizeMobileM;
        }
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px 18px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 18px;
        }
      }
    }
  }
}
</style>
