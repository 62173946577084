<template>
  <a
    :href="cardInfo.link"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    <div class="resource" :style="{ 'grid-template-rows': isEnglish ? '50px auto auto' : '32px auto auto'}">
      <img :alt="cardInfo.title" :src="getImageSource" class="resource__image" />
      <div class="resource__title" v-if="isEnglish">{{ cardInfo.titleEng }}</div>
      <div class="resource__title" v-else>{{ cardInfo.title }}</div>
      <div class="resource__link">
        {{ cardInfo.linkText }}
      </div>
      <div class="resource__tag" v-if="isEnglish">{{ cardInfo.tagEng }}</div>
      <div class="resource__tag" v-else>{{ cardInfo.tag }}</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "ResourcesItem",
  props: {
    cardInfo: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    getImageSource() {
      return require(`@/assets/images/${this.cardInfo.imageSrc}.png`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.resource {
  width: 263px;
  height: 122px;
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-areas:
    "img title"
    ". link"
    "tag tag";
  align-items: center;
  padding: 18px 5px 5px;
  background-color: $white-color;
  border-radius: $borderRadius;
  box-shadow: $boxShadowLight;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    width: 255px;
    height: 106px;
    padding: 8px 10px 10px 8px;
    border-radius: $borderRadiusMobile;
    box-shadow: $boxShadowMobile;
  }

  &:hover {
    box-shadow: $boxShadowHoverLight;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      box-shadow: $boxShadowMobileClicked;
    }
  }

  &:active {
    box-shadow: $boxShadowHoverLight;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      box-shadow: $boxShadowMobileClicked;
    }
  }

  &__image {
    width: 40px;
    grid-area: img;
    align-self: start;
    justify-self: center;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 35px;
    }
  }

  &__title {
    grid-area: title;
    align-self: start;
    padding-top: 2px;
    color: $black-color;
    font-size: 13px;
    line-height: 18px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: $fontSizeMobileL;
      line-height: 15px;
    }
  }

  &__link {
    grid-area: link;
    color: $thirdColor;
    font-size: 13px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: $fontSizeMobileL;
      line-height: 15px;
    }
  }

  &__tag {
    grid-area: tag;
    padding-right: 10px;
    color: $grey-color;
    text-align: right;
    font-size: 10px;
    line-height: 14px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      align-self: end;
      padding-right: 0;
      font-size: $fontSizeMobileS;
      line-height: 11px;
    }
  }
}

</style>